.login-page {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;
  margin-top: 60mm;

  .login-loader {
    margin-bottom: 3rem;
  }

  img {
    width: 48px;
    margin-bottom: 48px;
  }

  .login-options {
    width: 450px;
    padding: 40px;
    background: #ffffff;
    box-shadow: 0px 10px 64px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    border: none;

    h3 {
      text-align: center;
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
      font-family: Euclid Circular A Bold;
    }

    button {
      display: block;
      align-items: center;
      margin: 24px 0px;
      border: 1px solid #4a4a4c;
      box-sizing: border-box;
      border-radius: 8px;
      background: none;
      width: 100%;
      padding: 12px 24px;
      font-family: "Euclid Circular A Light";
      font-size: 14px;

      &:hover {
        cursor: pointer;
      }

      i {
        margin-right: 16px;
      }
    }

    .use-email {
      .back-to-signin {
        background: rgba(18, 18, 18, 0.08);
        border-radius: 8px;
        width: fit-content;
        padding: 8px;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.2px;
        color: #121212;
        margin-bottom: 32px;

        i {
          margin-right: 9.5px;
        }
      }
      h3 {
        margin-bottom: 16px;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.4px;
        color: #121212;
      }

      p {
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.4px;
        color: #121212;
        font-family: "Euclid Circular A Light";
        text-align: center;
        margin: 16px 0px;

        .added-email {
          font-weight: bold;
          font-family: "Euclid Circular A Bold";
        }
      }

      input {
        height: 56px;
      }

      button {
        background: #121212;
        border-radius: 6px;
        color: #fff;
        font-size: 15px;
        line-height: 16px;
        letter-spacing: -0.4px;
        height: 48px;
      }
    }
  }
}

.user-details {
  padding: 1rem 5rem;
  &__back-button {
    background: rgba(18, 18, 18, 0.06);
    border-radius: 8px;
    padding: 8px 12px;
    width: fit-content;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.2px;
    color: #121212;
    border: none;

    i {
      margin-right: 8px;
    }

    &:hover {
      cursor: pointer;
    }
    &:focus {
      outline: none;
    }
  }
  &__details {
    margin-top: 40px;
    padding: 24px;
    border: none;

    h3 {
      margin-bottom: 0;
      font-size: 15px;
      line-height: 24px;
      color: #1e1147;
    }

    &-stats {
      margin-bottom: 8px;
      &__title {
        font-size: 13px;
        line-height: 13px;
        color: #777e90;
        margin-bottom: 8px;
      }
      &__value {
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.004em;
        color: #121212;
      }

      .row {
        .col-3 {
          margin-bottom: 24px;
        }
      }
    }
  }
}

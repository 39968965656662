.dashboard {
  &__overview {
    &.card {
      border: none;
    }
    background: #ffffff;
    box-shadow: 0px 10px 25px rgba(151, 143, 175, 0.04);
    border-radius: 8px;
    padding: 24px;
    margin-bottom: 40px;

    &-text__select {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #edeff5;
      padding-bottom: 16px;
      align-items: baseline;

      h4 {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #121212;
        font-family: "Euclid Circular A SemiBold";
      }

      &-select_div {
        display: flex;
        align-items: center;
        border: 1px solid #edeff5;
        border-radius: 4px;
        padding: 0px 16px;

        select {
          border: none;
          box-sizing: border-box;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          display: flex;
          align-items: center;
          color: #4a4a4c;
          padding: 8px;
          font-family: "Euclid Circular A Light";

          &:focus {
            outline: none;
          }
          &:hover {
            cursor: pointer;
          }
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    &-cards__data {
      display: flex;
      justify-content: space-between;

      &__total {
        margin: 2rem;
        border-radius: 16px;
        text-align: center;
        width: 28rem;
        padding: 2rem;

        &.events {
          background: #fff9ea;
          border: 1px solid #ffe9b0;
        }

        &.users {
          background: #e8e8ff;
          border: 1px solid #adadff;
        }

        &.reported {
          background: #eafff2;
          border: 1px solid #acffcc;
        }

        h3 {
          font-size: 13px;
          line-height: 18px;
          text-align: center;
          letter-spacing: -0.2px;
          color: #121212;
          margin-bottom: 26px;
        }
        .total-no {
          font-style: normal;
          font-weight: 600;
          font-size: 25px;
          line-height: 32px;
          color: #121212;
          font-family: "Euclid Circular A Bold";
        }
        .stats {
          font-size: 13px;
          line-height: 14px;
          letter-spacing: -0.2px;
          color: #4a4a4c;
          margin-top: 26px;

          i {
            margin-right: 8px;
          }

          &.increase {
            i,
            span {
              font-size: 14px;
              line-height: 14px;
              letter-spacing: -0.2px;
              color: #15a336;
            }
          }
          &.decrease {
            i,
            span {
              font-size: 14px;
              line-height: 14px;
              letter-spacing: -0.2px;
              color: #eb0000;
            }
          }
        }
      }
    }
  }

  &__recent-events {
    &.card {
      border: none;
    }
    background: #ffffff;
    box-shadow: 0px 10px 25px rgba(151, 143, 175, 0.04);
    border-radius: 8px;
    padding: 24px;
    margin-bottom: 40px;
    &__text-select {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #edeff5;
      padding-bottom: 16px;
      align-items: baseline;

      h4 {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #121212;
        font-family: "Euclid Circular A SemiBold";
      }

      &-view-all {
        background: rgba(251, 193, 24, 0.12);
        border: 1px solid #fbc118;
        box-sizing: border-box;
        border-radius: 37px;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.01em;
        color: #fbc118;
        padding: 6px 14px;
        margin: 0px 8px;

        i {
          font-size: 10px;
          margin-left: 8px;
        }

        &:hover {
          cursor: pointer;
          text-decoration: none;
          color: #fbc118;
        }
      }
    }
  }
}

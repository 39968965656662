.details-slider .MuiDrawer-paper.MuiDrawer-paperAnchorRight {
  width: 650px !important;
  background: #fafafe;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.details-slider.has-video .MuiDrawer-paper.MuiDrawer-paperAnchorRight {
  width: 900px !important;
  background: #fafafe;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.status {
  padding: 5px 12px;
  border-radius: 48px;
  font-size: 11px;
  line-height: 16px;
  text-transform: lowercase;

  &.pre-approved {
    background: rgba(235, 0, 0, 0.1);
    color: #ff9900;
  }

  &.approved,
  &.active {
    background: rgba(21, 163, 54, 0.1);
    color: #15a336;
  }

  &.reported,
  &.banned {
    background: rgba(235, 0, 0, 0.1);
    color: #eb0000;
  }

  &.blocked {
    background: rgba(78, 77, 75, 0.1);
    color: #4e4d4b;
  }
}

.toast-text {
  font-size: 13px;
  display: flex;
  padding: 5px;
}

.skeleton-shimmer {
  padding: 10px;
  margin: 5px;

  &:first-of-type {
    margin-top: 10px;
  }
}

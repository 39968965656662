.users {
  .filter-options {
    display: flex;
    .search-box,
    .filter-box,
    .time-filter-box {
      display: flex;
      align-items: center;
      width: 275px;
      background: #fbfbfd;
      border: 1px solid #edeff5;
      padding: 8px 12px;
      border-radius: 4px;
      margin-right: 16px;
      i {
        color: #4a4a4c;
        font-size: 12px;
      }
      input,
      select {
        font-size: 12px;
        line-height: 24px;
        border: none;
        background: none;
        &::placeholder {
          color: #4a4a4c;
          font-family: "Euclid Circular A Light";
        }
        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  .users-list {
    width: 1250px;
    height: 735px;
    margin-top: 32px;
    border: none;
    box-shadow: 0px 10px 25px rgba(151, 143, 175, 0.04);
    border-radius: 16px;
    padding: 24px 32px;
  }
}

.header {
  padding: 24px;
  padding-bottom: 10px;

  img {
    width: 27px;
    margin-right: 8px;
    margin-top: -6px;
  }

  span {
    font-size: 20px;
    line-height: 15px;
    letter-spacing: -1px;
    color: #000000;
  }

  .user-name {
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    letter-spacing: -0.4px;
    color: #041549;
    margin-right: 8px;
    i {
      margin-left: 8px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .menu-options {
    p {
      margin-right: 25px;

      &.user-p {
        background: #bcbcbf;
        mix-blend-mode: normal;
        opacity: 0.2;
        padding: 4px;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        text-align: center;
      }
    }
  }
}

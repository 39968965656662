.landing-container {
  background: #fbfbfd;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05);
  height: 100vh;
  .header-row {
    background: #fff;
    border-bottom: 1px solid #f3f3f3;
    position: sticky;
    top: 0;
    z-index: 1000;
  }

  .menu-side {
    background: #fff;
    height: 85vh;
    position: sticky;
    left: 0;
    top: 85px;

    a {
      list-style: none;
      display: block;
      margin: 30px;
      padding: 1rem;
      font-size: 14px;
      line-height: 15px;
      letter-spacing: -0.2px;
      color: #4a4a4c;

      &:hover {
        text-decoration: none;
      }

      i {
        margin-right: 16px;
      }

      span {
        font-size: 13px;
        line-height: 15px;
        letter-spacing: -0.2px;
        color: #4a4a4c;
      }

      &.active {
        background: #fff8ea;
        border-radius: 6px;
        color: #121212;
        letter-spacing: -0.4px;
        line-height: 20px;

        i {
          color: #fbc118;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }

    .profile-bar {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 0;
      width: 100%;
      margin: 30px;
      padding: 1rem;

      i {
        background: #bcbcbf;
        mix-blend-mode: normal;
        opacity: 0.2;
        padding: 8px;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        text-align: center;
      }

      .names {
        margin-left: 16px;
        p {
          margin-bottom: 1px;
          letter-spacing: -0.4px;
          color: #121212;
          font-size: 14px;
          font-family: "Euclid Circular A SemiBold";
        }

        span {
          font-size: 12px;
          font-family: "Euclid Circular A Light";

          &:hover {
            cursor: pointer;
            text-decoration: underline;
            color: rgb(53, 53, 243);
          }
        }
      }
    }
  }

  .app-body {
    margin-top: 40px;
    background: #fbfbfd;
    padding-bottom: 10rem;
  }

  .status {
    padding: 4px 10px;
    border-radius: 48px;
    font-size: 12px;
    line-height: 16px;
    text-transform: lowercase;

    &.pre-approved {
      background: rgba(235, 0, 0, 0.1);
      color: #ff9900;
    }

    &.approved,
    &.active {
      background: rgba(21, 163, 54, 0.1);
      color: #15a336;
    }

    &.reported,
    &.banned {
      background: rgba(235, 0, 0, 0.1);
      color: #eb0000;
    }

    &.blocked {
      background: rgba(78, 77, 75, 0.1);
      color: #4e4d4b;
    }
  }
}

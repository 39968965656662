.event-details {
  padding: 32px;
  padding-top: 50px;
  font-family: "Euclid Circular A Regular";

  &__title-section {
    display: flex;
    justify-content: space-between;

    &__topic {
      display: flex;
      align-items: baseline;

      &__back {
        border: none;
        width: 32px;
        border-radius: 50%;
        align-items: center;
        height: 32px;

        &:focus {
          outline: none;
        }

        i {
          color: #fbc118;
          font-size: 14px;
        }

        &:hover {
          cursor: pointer;
        }
      }

      &__event-title {
        margin-left: 16px;
        font-weight: bold;
        font-size: 17px;
        line-height: 26px;
        display: flex;
        align-items: center;
        color: #121212;
      }
    }
    &__cancel {
      border: none;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      position: relative;
      top: 0px;
      filter: drop-shadow(0px 0.8px 16px rgba(0, 0, 0, 0.1));
      background: white;

      i {
        font-size: 11px;
        position: relative;
        top: -1px;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__details-card {
    margin-top: 32px;
    padding: 24px;
    background: #ffffff;
    box-shadow: 0px 10px 25px rgba(151, 143, 175, 0.08);
    border-radius: 8px;
    border: none;

    &__detail {
      margin-bottom: 24px;
      &__title {
        font-size: 12px;
        line-height: 16px;
        color: #777e90;
        margin-bottom: 8px;
      }
      &__value {
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.004em;
        color: #121212;
      }
      &-img {
        border-radius: 8px;
        height: 250px;
        width: 100%;
        object-fit: cover;
      }
      &-video {
        iframe {
          border-radius: 8px;
        }
      }
    }
  }

  &__action {
    margin-top: 24px;
    &__button {
      background: #121212;
      border-radius: 6px;
      width: 9rem;
      height: 45px;
      font-size: 13px;

      &__small {
        width: 40px;
        height: 45px;
        background: #23262f;
        border-radius: 0px 6px 6px 0px;
      }
    }

    &__list {
      margin-left: 1rem !important;

      li {
        span {
          color: #4a4a4c;
          font-size: 12px;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.events {
  .filter-options {
    display: flex;
    .search-box,
    .filter-box,
    .time-filter-box {
      display: flex;
      align-items: center;
      width: 275px;
      background: #fbfbfd;
      border: 1px solid #edeff5;
      padding: 8px 12px;
      border-radius: 4px;
      margin-right: 16px;
      i {
        color: #4a4a4c;
        font-size: 12px;
      }
      input,
      select {
        font-size: 12px;
        line-height: 24px;
        border: none;
        background: none;
        &::placeholder {
          color: #4a4a4c;
          font-family: "Euclid Circular A Light";
        }
        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  .events-list {
    width: 1300px;
    height: 819px;
    margin-top: 32px;
    border: none;
    box-shadow: 0px 10px 25px rgba(151, 143, 175, 0.04);
    border-radius: 16px;
    padding: 15px 32px;

    .list {
      display: flex;
      justify-content: space-between;
      width: 70%;
      margin-bottom: 20px;
      margin-top: inherit;
      margin-left: -39px;
      margin-bottom: 0;

      li {
        list-style: none;
        font-size: 13px;
        font-weight: 700;
        &.active {
          hr {
            height: 1.5px;
            background: #fbc118;
            border-radius: 2px 4px 0px 0px;
            margin-bottom: 0;
            padding-top: 2px;
            margin-top: 12px;
          }
        }
        &:hover {
          cursor: pointer;
        }

        hr {
          border: #fff;
        }
      }
    }

    hr {
      border: 0.5px solid rgba(0, 0, 0, 0.12);
      margin-top: 0;
      position: relative;
    }
  }
}

.data-table {
  .table {
    margin-top: 16px;
    table-layout: fixed;

    thead {
      border-radius: 8px;
      background: #fbfbfd;
      height: 48px;
      margin-bottom: 10px;
      tr {
        th {
          border: none;
          text-transform: uppercase;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 1px;
          font-family: "Euclid Circular A Light";
          color: #4a4a4c;

          &:first-child {
            width: 9%;
          }

          &:last-child {
            width: 6%;
          }
        }
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid #edeff5;
        height: 64px;

        &:last-child {
          border: none;
        }

        th,
        td {
          border: none;
          vertical-align: middle;
          font-size: 12.5px;

          // white-space: nowrap;
          // text-overflow: ellipsis;
          // overflow: hidden;

          .options {
            background: rgba(188, 188, 191, 0.1);
            border-radius: 40px;
            padding: 0px 10px;
            border: none;
            i {
              color: #121212;
            }
            &:hover {
              cursor: pointer;
            }
            &:focus {
              outline: none;
            }
          }
          .dropdown-item {
            font-size: 12px;
          }

          &:nth-child(2) {
            white-space: nowrap;
            max-width: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        &:hover {
          outline: 2px solid #fbc118;
          cursor: pointer;
          border-radius: 4px;
          background: #fffdf9;

          th {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
          }

          td {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
          }
        }
      }
    }
  }
  .simple-empty-table-text {
    text-align: center;
    padding: 5rem;
  }

  .big-table-empty-display {
    text-align: center;
    margin-top: 200px;
    img {
      width: 14rem;
      margin-bottom: 10px;
    }

    h4 {
      width: 39%;
      margin: auto;
      margin-bottom: 16px;
      font-family: "Euclid Circular A Light";
      font-size: 16px;
      line-height: 30px;
      text-align: center;
      color: #4a4a4c;

      span {
        font-family: "Euclid Circular A SemiBold";
      }
    }

    p {
      font-family: "Euclid Circular A Light";
      color: #777e90;
      font-size: 14px;
      line-height: 24px;
    }
  }
}

.pagination {
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  margin: 24px -25px;

  p {
    font-size: 14px;
    line-height: 15px;
    letter-spacing: -0.002em;
    color: #4a4a4c;
  }

  .pagination-spans {
    font-family: "Euclid Circular A Light";
    span {
      margin-right: 20px;
      font-size: 14px;
      line-height: 13px;

      &:hover {
        cursor: pointer;
      }

      &.active {
        border: 1px solid #fbc118;
        box-sizing: border-box;
        border-radius: 4px;
        text-align: center;
        letter-spacing: -0.002em;
        padding: 3px 15px;
        color: #fbc118;
      }
    }
  }
}

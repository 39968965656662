.confirmation-modal {
  .MuiDialog-paperScrollPaper {
    width: 460px;
    height: auto;
    border-radius: 8px;
  }
  &__dialog_title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__action-title {
      display: flex;
      align-items: baseline;
      &__icon {
        background: #12121240;
        border-radius: 24px;
        width: 28px;
        text-align: center;
        height: 28px;
        margin-bottom: 0;
        position: relative;
        top: 3px;

        i {
          color: black;
          font-size: 13px;
          text-align: center;
          position: relative;
          top: -3px;
          left: 1px;
        }

        &.__reject {
          background: #eb00004d;
          i {
            color: #eb0000;
          }
        }
      }

      &__name {
        margin-left: 8px;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: -0.2px;
        font-family: "Euclid Circular A SemiBold";
      }
    }

    &__close {
      width: 25px;
      border: 0.75px solid #e2e2e2;
      box-sizing: border-box;
      border-radius: 75px;
      height: 25px;
      font-size: 11px;
      text-align: center;
      background: none;

      &:focus {
        outline: none;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__dialog_content {
    &__text {
      font-size: 13px !important;
      line-height: 24px !important;
      color: #000211 !important;
      font-family: "Euclid Circular A Light" !important;

      &__block_text_first {
        &__event_name {
          font-weight: 900;
          font-family: "Euclid Circular A SemiBold" !important;
        }
      }

      &__block_input {
        height: 56px;
        font-size: 13px;
        &:focus {
          box-shadow: none !important;
        }
      }
    }
  }

  &__dialog_actions {
    justify-content: flex-start !important;
    margin: 10px;

    &__button_confirm {
      width: 175px;
      height: 44px;
      left: 0px;
      bottom: 0px;
      filter: drop-shadow(0px 10px 20px rgba(18, 18, 18, 0.2));
      background: #121212 !important;
      border-radius: 8px !important;
      color: white !important;
      text-transform: none !important;
      font-family: "Euclid Circular A Regular" !important;
      font-size: 12px !important;

      &:disabled {
        background: #4e4d4b !important;

        &:hover {
          cursor: not-allowed;
        }
      }

      &.__reject {
        background: #eb0000 !important;
      }
    }
    &__button_cancel {
      width: 110px;
      height: 44px;
      left: 0px;
      bottom: 0px;
      filter: drop-shadow(0px 10px 20px rgba(18, 18, 18, 0.2));
      border: 1px solid #121212 !important;
      color: #121212 !important;
      border-radius: 8px !important;
      text-transform: none !important;
      font-family: "Euclid Circular A Regular" !important;
      font-size: 12px !important;
    }
  }
}
